header {
    position: relative;
    z-index: 100;
    transition: all ease-in-out $duration;
}

.header-top {

    > .container {
        position: relative;
        display: flex;
        align-items: center;
    }

    .header__identity {
        display: flex;
        align-items: center;
        margin-right: 25px;

        .identity {
            line-height: 0;

            img {
                transition: all ease-in-out $duration;
            }
        }

        &__title {
            display: block;
            margin: 0;
            font-size: 0;
            line-height: 0;
        }

    }

}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

.tools {
    flex: auto;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    margin-top: 33px;

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        background: $color-bg--transparent;
    }

    .tool {
        display: flex;
        justify-content: center;
        align-items: stretch;
        width: 40px;
        height: 40px;
        margin: 0 10px;
        padding: 10px;
        border: 0;
        
        svg {
            fill: $color-text;
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            cursor: pointer;
            background-color: $color-dark;

            svg {
                fill: $color-white;
                transition: all $duration ease-in-out;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__translate {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__accessibility {
        background-color: $color-second;

        svg {
            width: 20px;
            height: 17px;
        }
    }

    &__profile.tool {
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        height: auto;
        margin-right: 30px;
        margin-left: 0;
        padding: 0;
        background-color: $color-bg--transparent;

        svg {
            width: 36px;
            height: 36px;
            fill: $color-second;
        }

        span {
            position: relative;
            margin-left: 10px;
            font-size: 1.25rem;
            font-weight: $font-weight;
            padding: 5px 0;
            border-bottom: 2px solid $color-second;
            transition: all $duration ease-in-out;

            svg {
                width: 15px;
                height: 15px;
                margin-left: 10px;
                fill: $color-bg--transparent;
            }

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: -2px;
                width: 0;
                height: 2px;
                background: $color-text;
                transition: all $duration;
            }
        }

        &:hover, &:focus {
            background-color: $color-bg--transparent;

            svg {
                fill: $color-second;
            }

            span {
                svg {
                    fill: $color-bg--transparent;
                }

                &::before {
                    width: 100%;
                    transition: all $duration ease-in-out;
                }
            }
        }
    }

    &__search {
        background-color: $color-third;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
        
        svg {
            width: 18px;
            height: 18px;
        }
    }
}

.tools-view {
    position: absolute;
    top: 106px;
    right: 87px;
    z-index: 2;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 260px;
            height: auto;
            padding: 20px;
            background: $color-dark;
            border-radius: $border-radius;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 0 0px 10px 0px;
                font-weight: $font-weight--heading;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}


//======================================================================================================
// Header Fixed
//======================================================================================================

.header--fixed .header-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-white;
    transition: all ease-in-out $duration;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));

    .tools {
        margin-top: 0;
    }

    .identity img {
        width: 70px;
        height: 63px;
        transition: all ease-in-out $duration;
    }

    #nav-main {
        .menu__nav__list > li {
            height: 83px;
            padding: 30px 65px 30px 25px;
            transition: all ease-in-out $duration;
        }

        .nav-main-item {
            .nav-main-link {
                font-size: 1.25rem;
                transition: all ease-in-out $duration;
    
                span {
                    display: inline;
                }
    
                &::before {
                    bottom: -29px;
                }

                &::after {
                    bottom: -5px;
                }
    
                &:hover, &:focus {
                    &::after {
                        bottom: -29px;
                        height: 25px;
                    }
                }
            }

            .sub-menu {
                top: 83px;
                border-radius: 0 0 $border-radius $border-radius;
                box-shadow: none;
                border-top: 2px solid $color-main;
            }

            &:nth-child(2) {
                .sub-menu {
                    border-color: $color-second;
                }
            }
            
            &:nth-child(3) {
                .sub-menu {
                    border-color: $color-third;
                }
            }

            &:hover, &:focus {
                .nav-main-link {
                    &::after {
                        bottom: -29px;
                        height: 25px;
                    }
                }
            }
        }
    }

    .tools__profile.tool {
        align-items: center;
        margin-right: 103px;
    }

    .tools-view {
        top: 58px;
        right: 142px;
    }
    
}
.admin-bar .header--fixed .header-top {
    top: 32px;
}


//======================================================================================================
// Quick Access
//======================================================================================================
.quick-access {
    position: fixed;
    top: 0;
    right: -910px;
    z-index: 15;
    display: block;
    height: 100%;
    width: 920px;
    background: $color-second;
    transition: all 0.75s;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 820px;
        top: 50%;
        width: 230px;
        height: 50px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        transform: rotate(-90deg);

        span {
            margin-top: 10px;

            &::before,
            &::after {
                content: "\f343";
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 30px;
                color: $color-white;
                transition: all $duration;
            }

            &::after {
                left: 30px;
                right: inherit;
            }
        }
        
        svg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-second;
            transform: rotate(180deg);
        }
    }

    &__container {
        padding-left: 75px;
        height: 100%;

        h2 {
            color: $color-white;
            border: 0;
            margin: 90px 0 50px;
            text-transform: uppercase;
        }

        ul {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 560px;
            max-height: 560px;

            li {
                width: calc(50% - 30px);
                margin-bottom: 15px;

                a {
                    display: block;
                    width: 100%;
                    color: $color-white;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &.open {
        right: 0;

        .quick-access__title {
            span {
                &::before,
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .identity img {
        width: 98px;
        height: auto;
    }

    .tools-view {
        top: 85px;
        right: 71px;
    }
    .tools__profile.tool {
        margin-right: 20px;
    }
    .tools {
        margin-top: 13px;
    }

    .header--fixed .header-top {
        #nav-main .menu__nav__list > li {
            padding: 30px 15px;
        }
        .tools__profile.tool {
            margin-right: 43px;
        }
        .identity img {
            width: 70px;
            height: 63px;
        }
    }

    .quick-access {
        width: 890px;
        right: -880px;

        &__title {
            right: 790px;
        }
    }

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

}


// 960
@media screen and (max-width: $medium) {

    .header-top .header__identity__title {
        margin: 15px 0;
    }

    .identity {
        img {
            width: 80px;
        }
    }

    .tools {
        margin-top: 0;
    }
    .tools .tool {
        margin: 0px 7.5px;
    }
    .tools__profile.tool {
        margin-right: 7.5px;
    }
    .tools-view {
        top: 69px;
        right: 52px;
    }


    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .header-top {
        
        > .container {
            flex-wrap: wrap;
        }
        
        .header__identity {
            margin-right: 0;

            &__title {
                margin: 20px 0;
            }
        }
    } 

    .identity {
        img {
            width: 122px;
        }
    }

    .tools {
        justify-content: flex-start;
        width: 50%;

        .tool {
            margin: 0 10px;
        }

        &__accessibility {
            margin-left: 0;
        }

        &__content {
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-left: 20px;
        }

        &__profile.tool {
            margin: 35px 0 0;
            order: 1;

            > svg {
                display: none;
            }
        }
    }

    .tools-view {
        top: 129px;
        right: 10px;

        .container--tools-view .profils {
            width: 300px;
        }
    }

}
