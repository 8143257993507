.footer {
    display: flex;
    flex-direction: column;

    &__content {
        position: relative;
        background-color: #ecf0b2;

        .container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 50%;
            background-color: #9fc0df;
            z-index: 0;
        }

        img {
            position: relative;
            z-index: 1;
            height: 368px;
            margin-left: -100px;
        }
    }

    &__title {
        color: $color-text;
        font-size: 1.5rem;
        font-weight: $font-weight-medium;
        margin: 0 0 10px;
    }

    &__infos {
        display: flex;
        align-items: stretch;
        padding: 105px 0;

        &__coordonnees {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 200px;
        }

        &__horaires {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 240px;
            margin-left: 60px;
        }
    }

    &__phone {
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding-bottom: 5px;
        font-weight: $font-weight-medium;
        font-size: 1.125rem;
        border-bottom: 2px solid $color-main;
        
        svg {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            fill: $color-main;
            transition: all ease-in-out $duration;
        }

        &:hover, &:focus {
            border-color: $color-text;
            color: $color-text;

            svg {
                fill: $color-text;
                transition: all ease-in-out $duration;
            }
        }
    }

    &__button {
        width: 100%;
        margin: 0;
        margin-top: 15px;
        background-color: $color-white;
        border: 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        color: $color-text;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 15px;
            fill: $color-main;
        }

        &:hover, &:focus {
            svg {
                fill: $color-text;
            }
        }
    }

    &__menu {
        position: relative;
        z-index: 1;
        background-color: $color-white;

        &__list  {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        &__item {

            &:last-child .footer__menu__link {
                &::after {
                    display: none;
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            padding: 20px 30px;
            font-size: $font-size--text-small;
            color: $color-text;
            text-align: center;

            &:hover, &:focus {
                color: $color-link--hover;
                text-decoration: underline;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 25%;
                background-color: $color-main;
                border-radius: 50px;
                transform: translateY(-50%);
                opacity: 1;
                transition: opacity $duration ease-in-out;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &__content img {
            height: 300px;
            width: 559px;
        }

        &__infos {
            padding: 73px 0;
            z-index: 1;
            position: relative;
            background: #ecf0b2;
        }

        &__menu__link {
            padding: 20px;
        }

    }
    
}


// 960
@media screen and (max-width: $medium) {

    .footer {

        &__content {
            &::after {
                display: none;
            }
            img {
                display: none;
            }
        }

        &__infos {
            justify-content: space-between;
            width: 100%;
        }

        &__menu {
            &__list {
                padding: 20px 50px;
            }
            &__link {
                padding: 10px 18px;
            }
        }

    }
    
}


// 640
@media screen and (max-width: $small) {

    .footer {

        &__infos {
            justify-content: center;
            flex-wrap: wrap;

            &__coordonnees, &__horaires {
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
            }
            
            &__horaires {
                margin-left: 0;
                margin-top: 50px;
            }
        }

        &__menu {
            &__list {
                padding: 20px 0;
            }
        }

       &__button {
            width: 80%;
        }

    }
    
}