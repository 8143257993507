.cover {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 200px;
        z-index: -1;
        background-color: $color-fourth--rgba;
    }

    &__content {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            right: 380px;
            top: -93px;
            width: 104px;
            height: 143px;
            background: url(/wp-content/themes/medis/assets/src/images/sprite-images/deco/header.svg) no-repeat;
            background-size: contain;
            background-position: 50%;
            z-index: -1;
        }
    }

    &__image-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 480px;
        background-color: $color-bg--image;
        border-radius: $border-radius;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

    }

    &__image-title {
        position: absolute;
        top: 30px;
        left: 30px;
        line-height: 1;

        span {
            display: block;
            text-align: left;
        }

        &--main {
            font-size: 4.375rem;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }

        &--secondary {
            font-size: 2.488rem;
            font-weight: $font-weight--heading;
            font-style: italic;
            color: $color-main;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        }
    }

    &__informations {
        padding: 30px 0 50px 0;
    }

    &__title {
        color: $color-text;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-light;
            font-size: 1.125rem;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 400px;
        }
        
        &__content::before {
            right: 363px;
            width: 96px;
            height: 133px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 445px;
        }

        &__content::before {
            top: -67px;
            right: 330px;
            width: 69.64px;
            height: 95.75px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {      
        &__image-wrapper {
            height: 235px;
        }

        &__image-title {
            span {
                text-align: center;
            }

            &--main {
                font-size: 3.125rem;
            }
            &--secondary {
                font-size: 1.5rem;
            }
        }

        &__content::before {
            display: none;
        }
    }

}
