.page-pagination {
    text-align: center;
    margin: 0 0 30px 0;

    p {
        margin-top: 15px;
        margin-bottom: 10px;
        font-weight: $font-weight-medium;
        font-size: $font-size--text-small;
        color: $color-gray;
        text-transform: uppercase;
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .page-pagination {     
        margin-top: 15px;
    } 

} 
