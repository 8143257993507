//=================================================================================================
// Display for all input fields (except buttons and checkbox)
//=================================================================================================
%forms {
    display: block;
    outline: none;
    width: 100%;
    max-width: 100%;
    padding: 5px 4px;
    background: $input-bg;
    border: $form-border;
    border-radius: $border-radius;
    line-height: 1;
}


//=================================================================================================
// Variable containing all input fields
//=================================================================================================
#{$forms} {
    @extend %forms;
}

textarea {
    height: auto;
    overflow: auto;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 1px solid $border-color;
    border-radius: $border-radius;
}

legend {
    padding: 0 .5rem;
    font-weight: $font-weight-semibold;
}


//=================================================================================================
// Fix issues with select
//=================================================================================================
select {
    line-height: 1;
    color: $color-text;
}

select::-ms-expand {
    display: none;
}


//=================================================================================================
// Make range full width
//=================================================================================================
[type=range] {
    width: 100%;
}


//=================================================================================================
// Labels
//=================================================================================================
label {
    display: block;
    max-width: 100%;
    font-weight: $font-weight-semibold;
    margin: 15px 0 0;
}


//=================================================================================================
// Form validation
//=================================================================================================
input:not([type=submit]):not([type=button]),
select,
textarea {
    border-color: $color-text;

    &.has-error {
        border: 1px solid $color-error;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), 0 0 6px lighten($color-error, 35%);
    }

    &.is-success {
        border: 1px solid $color-success;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), 0 0 6px lighten($color-success, 25%);
    }

    &:hover,
    &:focus,
    &:active {
        &.has-error {
            @extend .has-error;
        }

        &.is-success {
            @extend .is-success;
        }
    }
}

.container .acf-field {
    input[type="text"], 
    input[type="password"], 
    input[type="date"], 
    input[type="datetime"], 
    input[type="datetime-local"], 
    input[type="email"], 
    input[type="month"], 
    input[type="number"], 
    input[type="search"], 
    input[type="tel"], 
    input[type="time"], 
    input[type="url"],
    input[type="week"], 
    textarea, 
    select {
        padding: 10px;
    }
}


//=================================================================================================
// Placeholder Text
//=================================================================================================
::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: $placeholder;
}


//=================================================================================================
// Gravity Forms
//=================================================================================================
.gform_wrapper {

    .gform_validation_errors {
        margin: 0 0 15px;
        padding: 15px 30px;
        background: $color-error;
        border-left: 6px solid;
        border-color: $color-error;
        border-radius: 0;

        h2 {
            margin: 0;
            padding: 0;
            font-size: $font-size--text;
            font-style: normal;
            font-weight: $font-weight-bold;
            color: $color-white;
            line-height: $line-height;

            &::before {
                display: none;
            }
        }
    }

    form {
        input:not([type=submit]):not([type=button]),
        select,
        textarea {
            border-color: $color-gray;
        }

        select {
            padding: 5px 4px!important;
        }

        .description_above {
            .gfield_description {
                font-size: $font-size--text;
                color: $color-gray;
                font-style: italic;
            }
        }

        .gsection {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-top: 30px !important;
        }

        h2.gsection_title {
            margin: inherit!important;
            padding-bottom: 10px!important; 
        }

        li.gfield {
            margin-right: 0;
            padding-right: 0!important;

            &.gfield_error {
                padding-bottom: 0;
                padding-top: 0;
                background: inherit;
                border: inherit;
                border-top: 0;
                border-bottom: 0;

                .gfield_label,
                div.ginput_complex.ginput_container label {
                    color: inherit;
                }

                input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
                textarea {
                    border-color: $color-gray;
                }
            }

            label {
                font-weight: $font-weight;
            }

            & > label {
                font-weight: $font-weight-bold;
            }

            .ginput_container_fileupload .validation_message {
                display: none;
            }
        }

        ul.gfield_checkbox li,
        ul.gfield_radio li {
            display: flex;
            align-items: baseline;
            padding: 0!important;
        }

        ul.gfield_checkbox li input {
            margin-right: 10px;
        }

        .validation_message,
        .acf-error-file {
            margin: 5px 0 15px;
            padding: 10px 20px !important;
            background: $color-bg-error;
            border-left: 6px solid $color-error !important;
            font-size: $font-size--text !important;
            font-style: normal !important;
            font-weight: $font-weight-bold;
            color: $color-error !important;
            line-height: $line-height;
        }
    }
}

.gform_wrapper .gform_body input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: 10px;
}

.bloc-content--form {
    padding: 0;
}

.rgpd-container {
    display: flex;
    align-items: center;
    margin-top: 20px;

    input {
        flex: none;
        margin-right: 10px;
        align-self: unset!important;
    }

    label {
        margin: 5px 0;
    }
}

.gform_footer.top_label {
    display: block!important;
}

.submit-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 20px 0 0;

    .gpoll_summary {
        width: 100%;

        .gpoll_wrapper {
            .gpoll_ratio_box {
                background: $color-main;
            }
            .gpoll_bar_juice{
                background-color: $color-main;
                background-image: none;
            }
        }
    }

    .gpoll_button,
    .gpoll_back_button {
        display: inline-flex;
        align-items: center;
        margin: 5px 14px 5px 0;
        padding: $btn-second-padding;
        background: $btn-second-bg;
        border-radius: $btn-border-radius;
        border: $btn-border-width $btn-border-style $btn-second-border-color;
        font-family: $btn-font-family;
        font-weight: $btn-font-weight;
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        text-transform: $btn-text-transform;
        text-align: center;
        color: $btn-second-color;

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-checked {
            background: $btn-second-bg--hover;
            border-color: $btn-second-border-color--hover;
            color: $btn-second-color--hover;
        }
    }

    .gpoll_button {
        margin: 0 0 0 14px;
    }
}

button[disabled],
input[type=submit][disabled] {
    pointer-events: none;
    opacity: 0.8;
}

#rgpd-link {
    font-weight: $font-weight;
    color: $color-link;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.required {
    color: $color-error;
}

.gform_wrapper form .gform_footer input.button,
.gform_wrapper form .gform_footer input[type=submit],
.gform_wrapper form .gform_page_footer input.button,
.gform_wrapper form .gform_page_footer input[type=submit] {
    font-size: $btn-font-size;
}


//=================================================================================================
// ACF Forms
//=================================================================================================
form {
    .acf-fields {
        .acf-field,
        .acf-field:first-child {
            padding: 0;
            margin-top: 30px;
            border: 0;

            .acf-label {
                margin: 0 0 5px;

                .description {
                    color: $color-gray;
                    font-style: italic;
                    line-height: 1.2;
                }
            }

            .acf-image-uploader::before,
            .acf-file-uploader::before {
                content: "En nous faisant parvenir une image, vous certifiez en être le propriétaire et vous nous autorisez à l'utiliser sur le site.";
                position: relative;
                display: block;
                margin: 0 0 10px;
                color: $color-gray;
                font-style: italic;
            }

            .acf-file-uploader::before {
                content: "En nous faisant parvenir un média, vous certifiez en être le propriétaire et vous nous autorisez à l'utiliser sur le site.";
            }

            .acf-error-file {
                p {
                margin-top: 10px;
                font-weight: $font-weight-bold;
                color: $color-error;
                font-size: $font-size--text-small;

                    &::before {
                        content: "\f348";
                        font: normal 15px/1 dashicons;
                        position: relative;
                        top: 3px;
                        margin-right: 3px;
                    }
                }
            }

            .acf-fields {
                border: 0;

                .acf-field[data-name=top_post_images] > .acf-label{
                    display: none;
                }

                &.acf-fields-names {
                    display: flex;
                    justify-content: space-between;

                    .acf-field {
                        margin-top: 0;
                        width: calc(50% - 15px);
                    }
                }
            }

            &[data-name=primary_image] .acf-label::after,
            &[data-name=document] .acf-label::after {
                content: "Poids maximal autorisé : 1 Mo";
                position: relative;
                display: block;
                margin: 10px 0 0;
                color: $color-gray;
                font-style: italic; 
            }

            &[data-type=message] {
                display: none;
            }

            &[data-name=document] .acf-label::after {
                content: "Poids maximal autorisé : 25 Mo";
            }

            &[data-name=other_lang] {
                margin-top: 0;

                .acf-label {
                    display: inline-block;
                    margin: 10px 0;

                    label {
                        position: absolute;
                        right: -15px;
                    }
                    .description {
                        display: inline-block;
                    }
                }
            }

            &[data-name=file_dating_group] {
                margin-top: 0;
                margin-bottom: 30px;

                & > .acf-label {
                    display: none;
                }

                & > .acf-input > .acf-fields > .acf-field {
                    margin-top: 0;
                    margin-right: 30px;
                    width: calc(33.33% - 20px)!important; 
                    border: 0;

                    &:last-child {
                        margin-right: 0;
                    }

                    label {
                        margin-top: 5px;
                        font-weight: $font-weight;
                    }
                }
            }

            &[data-name=text_transcription] {
                margin-top: 0;

                & > .acf-label label {
                    margin: 10px 0;
                    font-weight: $font-weight;
                }
            }

            &.rgpd > label {
                font-weight: $font-weight;
            }

            .acf-notice {
                border-width: 0!important;
                border-left-width: 6px!important;

                p {
                    margin: 0;
                    padding: 7px 0;
                    font-size: $font-size--text;
                    font-weight: $font-weight-bold;
                }
            }

            &[data-name=event_detail_group] {

                & > .acf-label {
                    display: none;
                }

                .acf-field {
                    &[data-name=event_date_group] {

                        & > .acf-label {
                            display: none;
                        }

                        .acf-field {
                            &[data-name=dates] {
                                position: relative;
                                padding-bottom: 40px;

                                & > .acf-label {
                                    position: absolute;
                                    bottom: 0;
                                }

                                .acf-fields {
                                    display: flex;
                                    justify-content: space-between;

                                    .acf-field {
                                        width: calc(50% - 15px);
                                    }
                                }
                            }
                            &[data-name=event_recurrence] {
                                display:none;
                            }
                        }

                        .acf-fields {
                            .acf-field {
                                margin-top: 0;
                            }
                        }
                    }

                    &[data-name=event_hours_group] {
                        margin-top: 0;

                        & > .acf-label {
                            display: none;
                        }
                        
                        .acf-fields {
                            display: flex;
                            justify-content: space-between;

                            .acf-field {
                                width: calc(50% - 15px);
                            }
                        }
                    }

                    &[data-name=location_group] {
                        .acf-field {
                            &[data-name=additional_address] {
                                margin-top: 0;

                                & > .acf-label {
                                    display: none;
                                }
                            }
                        }
                    }

                    &[data-name=tariff_group] {
                        .acf-field {
                            &[data-name=tarifs] {
                                margin-top: 0;
                            }
                        }
                    }

                    &[data-name=event_accessibility_group] {
                        .acf-field {
                            &[data-name=accessibility] {
                                margin-top: 10px;

                                .acf-checkbox-list {
                                    li {
                                        label {
                                            font-size: $font-size--text;
                                            font-weight: $font-weight;
                                        }

                                        &:first-child {
                                            label {
                                                font-weight: $font-weight-bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &[data-name=contact_detail_group] {
                & > .acf-label {
                    display: none;
                }

                .acf-field[data-name="informations_group"] {
                    margin-top: 10px;

                    .acf-field[data-name=support_choice] {
                        .acf-button-group {
                            label {
                                &.selected,
                                &:hover,
                                &:focus,
                                &:active {
                                    cursor: pointer;
                                    background: $color-main;
                                    border-color: $color-main;
                                    color: $color-white;
                                }
                            }
                        }
                    }
                }

                .acf-field[data-name=exclude_siret],
                .acf-field[data-name=exclude_rna] {
                    margin-top: 10px;
                    
                    ul {
                        li {
                            label {
                                display: inline-flex;
                                align-items: center;
                                margin: 0;
                                font-size: $font-size;
                                font-weight: $font-weight;

                                input[type=checkbox] {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .acf-date-picker:before {
            content: "\f145";
            font: normal 18px/1 dashicons;
            position: absolute;
            right: 10px;
            top: 12px;
            color: $color-text;
            pointer-events: none;
        }

        .acf-time-picker:before {
            content: "\f469";
            font: normal 18px/1 dashicons;
            position: absolute;
            right: 10px;
            top: 12px;
            color: $color-text;
            pointer-events: none;
        }

        & > .acf-notice {
            margin-top: 20px;
        }

        &::after {
            display: none;
        }
    }

    .acf-notice, .acf-notice {
        margin: 0 0 15px;
        padding: 3px 12px 3px 25px;
        border: 1px solid transparent !important;
        border-left-width: 6px !important;
    }

    .acf-notice.-success {
        border-left-color: $color-success !important;
    }
    .acf-notice.-warning {
        border-left-color: $color-alert !important;
    }
    .acf-notice.-error {
        border-left-color: $color-error !important;
    }

    .acf-field .acf-notice.-success {
        background: $color-bg-success;
        color: $color-success;
    }
    .acf-field .acf-notice.-warning {
        background: $color-bg-alert;
        color: $color-alert;
    }
    .acf-field .acf-notice.-error {
        background: $color-bg-error;
        color: $color-error;
    }

    .acf-notice p {
        font-size: $font-size--text;
        line-height: 1.4;
    }

    .acf-notice .acf-notice-dismiss {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 12px;
        background: $color-white !important;
        opacity: 1;
        font-size: $font-size--text-medium;
    }

    .acf-notice.-success .acf-notice-dismiss {
        color: $color-success !important;
    }
    .acf-notice.-warning .acf-notice-dismiss {
        color: $color-alert !important;
    }
    .acf-notice.-error .acf-notice-dismiss {
        color: $color-error !important;
    }
    .acf-required {
        color: #f00;
    }

    .buttons-container {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        &--rgpd {
            justify-content: flex-end;
        }

        [type=submit] {
            margin-right: 0;
        }
    }
}

.container .acf-button-group label {
    cursor: pointer;
    transition: all ease-in-out $duration;
    font-weight: $font-weight-medium;

    &.selected, &:hover {
        border-color: $color-text;
        background: $color-main;
        color: $color-text;
        transition: all ease-in-out $duration;
    }
}

.container {
    .acf-input-prepend, .acf-input-append {
        padding: 10px 15px;
        background: $color-light;
        border-color: $color-text;
    }
    
}

/*** PASSWORD FORM ***/
.password-description {
    margin-top: 30px;
}

.post-password-form {
    .post_password {
        max-width: 300px;
        margin: 5px 0 20px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 940
@media screen and (max-width: $medium) {
    form .acf-fields .acf-field[data-name=event_detail_group] .acf-field[data-name=event_date_group] .acf-field[data-name=dates] {
        padding-bottom: 50px;
    }
}


// 640
@media screen and (max-width: $small) {
    form .acf-fields .acf-field:first-child[data-name=file_dating_group]>.acf-input>.acf-fields>.acf-field, form .acf-fields .acf-field[data-name=file_dating_group]>.acf-input>.acf-fields>.acf-field {
        width: 100% !important;
    }
    form .acf-fields .acf-field[data-name=event_detail_group] .acf-field[data-name=event_date_group] .acf-field[data-name=dates] {
        padding-bottom: 70px;
    }


    form .buttons-container {
        flex-direction: column-reverse;

        a, button {
            width: 100%;
            margin: 5px 0;
        }
    }

}

//=================================================================================================
// Fix select grid- maj plugin GF 08/2023
//=================================================================================================
.gform-body {
    [class*="grid-"], [class*="grid_"], [class~="grid"] {
        display: unset;
        flex-flow: unset;
        margin: 0;

        @media screen and (max-width: $large) {
            margin: 0;
        }
    }
}

.gform_wrapper {
    &.gravity-theme {
        .ginput_container_time {
            max-width: 80px!important;
            min-width: 80px!important;
        }
    }

}

.gform_legacy_markup_wrapper div.ginput_complex.ginput_container {
  display: inherit;
}
