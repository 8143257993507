.acf-ui-datepicker {

    .ui-datepicker.ui-widget-content {
        width: 370px;
        border: 10px solid $color-main;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0px 0px 1px rgba(27, 35, 54, 0.08), 1px 1px 10px rgba(27, 35, 54, 0.08);
        transform: translate(0px, -33px);

        .ui-icon {
            filter: contrast(0.5);
        }

        .ui-datepicker-title {
            display: flex;
            justify-content: center;

            .ui-datepicker-month {
                margin-right: 10px;
            }

            .ui-datepicker-month,
            .ui-datepicker-year {
                width: 80px;
            }
        }

        .ui-widget-header {
            .ui-corner-all {
                border: 2px solid $color-bg--transparent;
            }
            .ui-state-hover {
                border-color: $color-main;
                background: $color-white;
                color: $color-text;
            }
        }

        td {
            a {
                padding: .5em;

                &.ui-state-default {
                    border: 1px solid #dcdcdc;
                    background: $color-bg--neutral;
                    color: $color-text;
                }

                &.ui-state-highlight {
                    background: $color-white;
                }

                &.ui-state-hover,
                &.ui-state-active {
                    border: 1px solid $color-main;
                    background: $color-main;
                    color: $color-text;

                    &.ui-state-highlight {
                        border: 1px solid $color-main !important;
                        background: $color-main !important;
                    }
                }

            }
        }

        button {
            border: $btn-border-width $btn-border-style $btn-second-border-color;
            color: $btn-second-color;
            background: $btn-second-bg;
            padding: 10px 25px;
            margin: 10px 0;
            font-weight: $btn-font-weight;
            opacity: 1;

            &:hover,
            &:focus,
            &:active,
            &.is-active,
            &.is-checked,
            &.ui-state-hover{
                color: $btn-second-color--hover;
                background: $btn-second-bg--hover;
                border: $btn-border-width $btn-border-style $btn-second-border-color--hover;
            }
        }
    }

    .ui-timepicker-div {
        dl {
            dd.ui_tpicker_second {
                display: none;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .acf-ui-datepicker {
        .ui-datepicker.ui-widget-content {
            width: 300px;
        }
    }
    
}
