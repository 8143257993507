#nav-main {

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;

        > li {
            height: 140px;
            padding: 45px 25px;
            transition: all ease-in-out $duration;
        }
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
            .nav-main-link {
                &::before {
                    opacity: 1;
                }
                &::after {
                    bottom: -45px;
                    height: 35px;
                    transition: all ease-in-out $duration;
                }
            }
        }

        &:nth-child(2) {
            .nav-main-link {
                &::after {
                    background-color: $color-second;
                }
            }

            .sub-menu {
                .nav-child-item a, .nav-grandchild-item a {
                    border-color: $color-second;
    
                    &::after {
                        color: $color-second;
                    }
    
                    &:hover, &:focus {
                        background-color: $color-second;
                    }
                }
            }
        }
        
        &:nth-child(3) {
           .nav-main-link {
                &::after {
                    background-color: $color-third;
                }
            }

            .sub-menu {
                .nav-child-item a, .nav-grandchild-item a {
                    border-color: $color-third;
    
                    &::after {
                        color: $color-third;
                    }
    
                    &:hover, &:focus {
                        background-color: $color-third;
                    }
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: block;
            color: $color-text;
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
            line-height: 1.2;
            transition: all ease-in-out $duration;

            span {
                display: block;
                font-size: 1.25rem;
                font-weight: $font-weight-normal;
            }

            &::before {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                bottom: -37px;
                left: 0;
                right: 0;
                z-index: 1;
                color: $color-white;
                opacity: 0;
                transform: rotate(90deg);
                text-align: center;
                transition: all $duration;                
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $color-main;
                border-radius: 2px 2px 0px 0px;
                transition: all ease-in-out $duration;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            top: 140px;
            z-index: 100;
            width: 100%;
            padding: 30px;
            background: $color-white;
            border-radius: $border-radius;
            box-shadow: $shadow;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 60px;
                    padding: 15px 20px 15px 0;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    border-bottom: 2px solid $color-main;
                    color: $color-text;
                    font-weight: $font-weight-medium;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 5px;
                        color: $color-main;
                        transition: all $duration;
                    }

                    &:hover, &:focus {
                        padding-left: 10px;
                        background-color: $color-main;
                        border-radius: $border-radius;

                        &::after {
                            color: $color-white;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        display: none;
                    }

                    &:hover, &:focus {
                        padding-left: 15px;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0 0 5px 0;
    color: $color-text;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    border-bottom: 2px solid $color-main;
    text-transform: uppercase;

    .navicon {
        position: relative;
        display: block;
        height: 3px;
        top: -1px;
        width: 20px;
        margin-right: 15px;
        background: $color-main;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-main;
            transition: all $duration ease-out;
        }

        &:before {
            top: 8px;
        }

        &:after {
            top: -8px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .menu__nav__list > li {
            height: 118px;
            padding: 33px 15px;
        }
        .nav-main-item {
            &.menu-item-has-children {
                &:focus-within, &:hover {
                    .nav-main-link::after {
                        bottom: -42px;
                        height: 34px;
                    }
                }
            }

            .nav-main-link {
                font-size: 1.25rem;

                span {
                    font-size: 1rem;
                }
            }

            .sub-menu {
                top: 118px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 71px;
            left: 15px;
            width: 100%;
            margin: auto -15px;
            border-radius: $border-radius;
            overflow: hidden;
            box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

            > li {
                height: auto;
                padding: 10px;
                background-color: $color-main;

                &:nth-child(2) {
                    background-color: $color-second;
                }

                &:nth-child(3) {
                    background-color: $color-third;
                }
            }
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                color: $color-text;
                text-transform: uppercase;

                span {
                    font-size: 1.25rem;
                    margin-right: 5px;
                }

                &:first-child {
                    padding: 0 20px;
                }

                &::before, &::after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-white;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    border-bottom: 1px solid $color-text;
                    color: $color-text;
                }

                .sub-menu {
                    top: 0;
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

    #nav-main {
        order: 1;
        width: 100%;

        .menu__nav__list {
            top: 205px;
        }

        .nav-main_icon {
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            padding: 10px;
            border: 0;
            border-radius: $border-radius;
            box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
        }
    }

}
