.breadcrumb {
    position: relative;
    z-index: 1;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    margin: -30px 35px 0 35px;
    padding: 20px;
    background-color: $color-white;
    border-radius: $border-radius;
    box-shadow: 0px 0px 10px 0px #00000026;

    li {
        position: relative;
        display: inline;
        padding: 0 15px 0 0;
        color: $color-text;
        font-size: $font-size--text-small;

        &:first-child {
            text-transform: uppercase;
            font-weight: $font-weight-medium;
        }

        a, span {
            position: relative;
            display: inline-flex;
            align-items: center;
            padding: 0 35px 0 0;
            line-height: 2;

            &::before {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-main;
                transition: all $duration;
            }
        }

        &:last-child {
            padding: 0;

            span {
                padding: 0;
                display: inline;

                &::before {
                    display: none;
                }
            }
        }

    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        margin: -30px 15px 0;
        padding: 15px;

        li {
            padding: 0;

            a, span {
                padding: 0 25px 0 0;
            }
        }
    }

}
