//======================================================================================================
// Style Home
//======================================================================================================
.home {
    &__title {
        position: relative;
        display: block;
        margin: 20px 0;
        padding-left: 70px;
        color: $color-text;
        font-family: $font-family--heading;
        font-size: 2.75rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        span {
            font-weight: $font-weight-light;
        }

        &--small {
            padding: 0;
            margin: 0 0 30px;
            font-size: 2.125rem;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
    }

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        margin-top: 35px;
        padding: 5px 10px;
        font-size: 1.125rem;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        text-decoration: none;

        &::after {
            content: ''; 
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            height: 2px;
            background-color: $color-main;
            transition: height ease-in-out $duration;
        }

        svg {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            fill: $color-main;
            transform: rotate(0deg);
            transition: all ease-in-out $duration;
        }

        &:hover, &:focus {
            color: $color-text;

            &::after {
                height: 100%;
                transition: height ease-in-out $duration;
            }

            svg {
                fill: $color-text;
                transform: rotate(90deg);
                transition: all ease-in-out $duration;
            }
        }
    }

    &__navigation {
        position: absolute;
        right: 15px;
        left: auto;
        top: 8px;
        bottom: auto;
        height: 40px;
        width: 110px;
        pointer-events: none;

        .swiper-button {
            pointer-events: auto;
            width: 40px;
            height: 40px;
            background-color: $color-main;
            border-radius: $border-radius;
            transition: all ease-in-out $duration;

            &::after {
                display: none;
            }

            svg {
                width: 17px;
                height: 14px;
                fill: $color-text;
                transition: all ease-in-out $duration;
            }

            &:hover {
                background-color: $color-dark;
                transition: all ease-in-out $duration;

                svg {
                    fill: $color-white;
                    transition: all ease-in-out $duration;
                }
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }


    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60%;
            background-color: $color-fourth--rgba;
            z-index: -1;
        }

        > .container {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                right: 380px;
                top: -93px;
                width: 104px;
                height: 143px;
                background: url(/wp-content/themes/medis/assets/src/images/sprite-images/deco/header.svg) no-repeat;
                background-size: contain;
                background-position: 50%;
                z-index: -1;
            }
        }

        &__image-title {
            position: absolute;
            top: 30px;
            left: 45px;
            line-height: 1;
            z-index: 10;

            span {
                display: block;
                text-align: left;
            }

            &--main {
                font-size: 4.375rem;
                font-weight: $font-weight-bold;
                color: $color-white;
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
            }

            &--secondary {
                font-size: 2.488rem;
                font-weight: $font-weight--heading;
                font-style: italic;
                color: $color-main;
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            }
        }

        .swiper-container {
            position: relative;
            height: 500px;
            width: 100%;
            border-radius: $border-radius;
            overflow: hidden;

            .swiper-slide {
                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: top;
                }
            }

            .container--pagination {
                position: absolute;
                right: 30px;
                left: auto;
                top: auto;
                bottom: 30px;
                height: 40px;
                width: 110px;
                pointer-events: none;

                .swiper-button {
                    pointer-events: auto;
                    width: 40px;
                    height: 40px;
                    background-color: $color-white;
                    border-radius: $border-radius;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
                    transition: all ease-in-out $duration;

                    &::after {
                        display: none;
                    }

                    svg {
                        width: 17px;
                        height: 14px;
                        fill: $color-text;
                        transition: all ease-in-out $duration;
                    }

                    &:hover {
                        background-color: $color-dark;
                        transition: all ease-in-out $duration;

                        svg {
                            fill: $color-white;
                            transition: all ease-in-out $duration;
                        }
                    }
                }

                .swiper-button-prev {
                    left: 0;
                }

                .swiper-button-next {
                    right: 0;
                }
            }
        }

        .slideshow {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            .container--slideshow {
                position: relative;
                height: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: auto;
                padding: 30px 170px 30px 30px;
                background-color: #CBD26C;
                color: $color-text;
                opacity: 0.9;

                &__svg {
                    display: block;
                    position: absolute;
                    top: -31px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 33px;
                    fill: #CBD26C; 
                }
            }

            &__title {
                margin-bottom: 5px;
                font-size: 1.375rem;
                font-weight: $font-weight-medium;
            }
        }

        .video-container {
            height: 500px;
            width: 100%;

            .slideshow {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;

                video,
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

                img {
                    display: none;
                }
            }
        }
    }


    //======================================================================================================
    // Access
    //======================================================================================================
    .access {
        position: relative;
        padding: 50px 0;
        background-color: $color-fourth--rgba;

    &__list {
            display: flex;
            align-items: stretch;

            li {
                flex: 1 1 0;
            }
        }

    &__link {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            color: $color-text;

        span {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight-medium;
                text-transform: uppercase;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 35%;
                    height: 2px;
                    background-color: $color-main;
                    border-radius: $border-radius;
                    transition: all ease-in-out $duration;
                }
            }

            &:hover, &:focus {
                color: $color-text;

                span {
                    &::after {
                        width: 110%;
                        transition: all ease-in-out $duration;
                    }
                }
            }
        }

    }


    //======================================================================================================
    // News
    //======================================================================================================
    .news {
        position: relative;
        padding: 80px 0;

        &__container {
            position: relative;
        }

        &__title {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: -37px;
                left: -19px;
                width: 85px;
                height: 133px;
                background: url(/wp-content/themes/medis/assets/src/images/sprite-images/deco/actus.svg) no-repeat;
                background-size: contain;
            }
        }

        &__button {
            border-color: $color-second;

            &::after {
                background-color: $color-second;
            }

            svg {
                fill: $color-second;
            }

            &:hover, &:focus {
                svg {
                    fill: $color-text;
                }
            }
        }

        .swiper-wrapper {
            display: flex;
            align-items: stretch;
        }
        
        .swiper-slide {
            overflow: hidden;
            width: 50%;
        }

        &__navigation {
            .swiper-button {
                background-color: $color-second;

                svg {
                    fill: $color-text;
                }

                &:hover {
                    background-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            overflow: hidden;
            width: 570px;
            height: 400px;
            margin: 0 15px;
            border-radius: $border-radius;
            color: $color-text;

            &:hover, &:focus {
                color: $color-text;

                img {
                    transform: scale(1.1);
                    transition: transform ease-in-out $duration;
                }

                .news__infos {
                    height: 170px;
                    transition: height ease-in-out $duration;

                    &__introduction {
                        opacity: 1;
                        height: 48px;
                        transition: all ease-in-out $duration;
                    }
                }
            }

        }

        &__content {
            overflow: hidden;
            line-height: 0;
            height: 321px;

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-bg--image;
                background-position: center;
            }

            img {
                transform: scale(1);
                transition: transform ease-in-out $duration;
            }
        }

        &__infos {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 130px;
            padding: 30px;
            background-color: $color-second;
            text-align: center;
            transition: height ease-in-out $duration;

            &__title {
                margin: 0;
                font-size: 1.5rem;
            }

            &__svg {
                display: block;
                position: absolute;
                top: -14px;
                left: 0%;
                right: 0;
                width: 100%;
                height: 16px;
                fill: $color-second; 
            }

            &__introduction {
                margin-top: 10px;
                opacity: 0;
                height: 0;
                transition: all ease-in-out $duration;
            }
        }        

    }


    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        position: relative;
        padding: 80px 0;
        background-color: $color-third--rgba;

        .swiper-container {
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
        }

        &__container {
            position: relative;
        }

        &__title {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: -47px;
                left: 4px;
                width: 55px;
                height: 120px;
                background: url(/wp-content/themes/medis/assets/src/images/sprite-images/deco/agenda.svg) no-repeat;
                background-size: contain;
            }
        }

        &__button {
            border-color: $color-third;

            svg {
                fill: $color-third;
            }

            &::after {
                background-color: $color-third;
            }

            &:hover, &:focus {
                svg {
                    fill: $color-text;
                }
            }
        }

        .swiper-wrapper {
            display: flex;
            align-items: stretch;
        }
        
        .swiper-slide {
            overflow: hidden;
            width: 33.33%;
        }

        &__navigation {
            .swiper-button {
                background-color: $color-third;

                svg {
                    fill: $color-text;
                }

                &:hover {
                    background-color: $color-dark;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: block;
            overflow: hidden;
            width: 370px;
            height: 436px;
            margin: 0 15px;
            border-radius: $border-radius;
            color: $color-text;

            &:hover, &:focus {
                color: $color-text;

                .events__infos {
                    padding: 45px 30px;
                    transition: padding ease-in-out $duration;
                }
            }

        }

        &__content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            overflow: hidden;
            height: 320px;
            width: 100%;

            img {
                width: 269px;
            }

            &--notimg {
                align-items: center;
                justify-content: flex-end;
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) no-repeat $color-bg--image;
                background-position: 35%;
            }
        }

        &__infos {
            position: absolute;
            bottom: 0;
            left: -1%;
            right: -1%;
            min-height: 115px;
            box-sizing: border-box;
            padding: 30px;
            background-color: $color-third;
            transition: padding ease-in-out $duration;
            text-align: center;

            &__title {
                margin: 0 0 10px 0;
                font-size: 1.5rem;
            }

            &__svg {
                display: block;
                position: absolute;
                top: -8px;
                left: 0%;
                right: 0;
                width: 100%;
                height: 10px;
                fill: $color-third; 
            }

            &__introduction {
                display: none;
                opacity: 0;
                transition: opacity ease-in-out $duration;
            }
        }

        &__date {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-sizing: border-box;
            width: 99px;
            height: 320px;
            padding: 15px;
            background-color: $color-white;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                line-height: 1.1;
            }

            &__day {
                font-size: 2.5rem;
                font-weight: $font-weight-bold;
            }

            &__month {
                font-size: 1.25rem;
                font-weight: $font-weight-medium;
                text-transform: uppercase;
            }

            svg {
                width: 18px;
                height: 10px;
                fill: $color-fourth;
                margin: 15px 0;
            }
        }

    }


    //======================================================================================================
    // Home Footer
    //======================================================================================================
    &__footer {
        position: relative;
        padding: 50px 0;

        &__container {
            display: flex;
            align-items: center;

            > svg {
                width: 63px;
                height: 200px;
                margin-right: 45px;
                fill: $color-second;
            }
        }

        &__list {
            display: flex;
            align-items: center;

            li {
                margin: 0 20px;
            }

            li:first-child {
                margin-left: 0;
                margin-right: 40px;
            }

            li:last-child {
                margin-right: 0;
            }

            li:nth-child(2) {
                margin-right: 65px;
            }

        }
        
        &__link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 10px;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 55%;
                height: 2px;
                background-color: $color-second;
                border-radius: $border-radius;
                transition: all ease-in-out $duration;
            }

            &__title {
                font-size: $font-size--text-small;
                font-weight: $font-weight--heading;
                text-transform: uppercase;
            }

            &__title, &__desc {
                display: block;
            }

            &__desc { 
                font-size: $font-size--text;
                font-weight: $font-weight;
            }

            &--big {
                flex-direction: row;
                padding: 10px 0;

                &::after {
                    left: 0;
                    width: 100%;
                }

                .home__footer__link__title {
                    font-size: $font-size--5;
                    text-transform: inherit;
                }

                svg {
                    margin-right: 13px;
                }

                &:hover, &:focus {
                    &::after {
                        width: 55% !important;
                        transition: width ease-in-out $duration;
                    }
                }
            }

            &:hover, &:focus {
                color: $color-text;

                &::after {
                    width: 100%;
                    transition: width ease-in-out $duration;
                }
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home {

        // Style Home
        &__title--small {
            padding: 0;
            margin: 0 0 10px;
            font-size: 2.125rem;
        }


        // Slideshow 
        .home-slideshow {
            .slideshow__content__svg {
                top: -28px;
                height: 34px;
            }

            > .container::before {
                right: 363px;
                width: 96px;
                height: 133px;
            }

            .swiper-container, .video-container {
                height: 400px;
            }
        }


        // Access 
        .access__link span {
            padding: 10px 15px;
            text-align: center;
            min-height: 64px;
        }


        // News 
        .news {
            &__link {
                width: 460px;
                height: 323px;

                &:hover, &:focus {
                    .news__infos {
                        height: 195px;
                    }
                }
            }

            &__content {
                height: 260px;
            }

            &__infos {
                height: 140px;

                &__svg {
                    height: 17px;
                }
            }
        }


        // Events 
        .events {
            &__link {
                width: 300px;
                height: 370px;
            }

            &__content {
                height: 260px;

                img {
                    width: 220px;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &__date {
                width: 80px;
                height: 260px;

                &__day {
                    font-size: 2.25rem;
                }

                &__month {
                    font-size: $font-size--text;
                }
            }

            &__infos__title {
                font-size: 1.25rem;
            }
        }


        // Home footer 
        &__footer {
            &__container > svg {
                width: 49px;
                height: 156px;
                margin-right: 25px;
            }

            &__list li {
                width: 82px;
                margin: 0 10px;

                &:first-child {
                    width: 208px;
                    margin-left: 0;
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    width: 325px;
                    margin-right: 20px;
                }
            }

            &__link--big {
                height: 82px;
            }
        }


    }
    
}


// 960
@media screen and (max-width: $medium) {

    .home {

        // Style Home 
        &__button {
            padding: 5px 0px;
        }


        // Slideshow 
        .home-slideshow {
            position: relative;

            .slideshow__content {
                padding: 30px;

                &__svg {
                    top: -23px;
                }
            }
    
            > .container::before {
                top: -67px;
                right: 330px;
                width: 69.64px;
                height: 95.75px;
            }

            .swiper-container, .video-container {
                height: 445px;
            }

            .swiper-container .container--pagination {
                right: auto;
                left: 35px;
                top: 170px;
                bottom: auto;
            }

            .video-container {
                .slideshow {
                    video {
                        display: none;
                    }
                    img {
                        display: block;
                    }
                }
            }
        }


        // Access
        .access {
            &__list {
                flex-wrap: wrap;

                li {
                    flex: 1 1 33%;

                    &:first-child, &:nth-child(2), &:nth-child(3) {
                        margin-bottom: 40px;
                    }
                }
            }
        }


        // News 
        .news {
            .swiper-slide {
                width: 100%;
            }

            &__content {
                height: 322px;
            }
            
            &__link {
                width: 620px;
                height: 407px;
                margin: 0;

                &:hover, &:focus {
                    .news__infos {
                        height: auto;
                    }
                }
            }

            &__infos {
                height: auto;

                &__introduction {
                    display: none;
                }

                &__svg {
                    height: 17px;
                }
            }
            
        }


        // Events 
        .events {
            .swiper-slide {
                width: 50%;
            }
        }


        // Home footer 
        &__footer {
            &__container > svg {
                margin-right: 20px;
            }

            &__list li {
                width: 98px;
                margin: 0 7.5px;

                &:first-child {
                    width: 102px;
                    margin-right: 7.5px;
                }

                &:nth-child(2) {
                    width: 98px;
                    margin-right: 7.5px;
                }
            }

            &__link {
                
                &__title {
                    margin-top: 5px;
                }

                &__desc {
                    display: none;
                }

                &--big {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: auto;
                    padding: 10px 0;
    
                    svg {
                        width: 40px;
                        height: 40px;
                        margin: 0;
                    }
    
                    &::after {
                        left: auto;
                        width: 55%;
                    }
    
                    &:hover, &:focus {
                        &::after {
                            width: 100% !important;
                        }
                    }
    
                    .home__footer__link__title {
                        font-size: $font-size--text-small;
                        text-transform: uppercase;
                    }
                }
            }

        }

    }

}


// 640
@media screen and (max-width: $small) {

    .home {

        // Style Home
        &__title {
            padding: 0;
            font-size: 2.125rem;
            text-align: center;

            &::before {
                display: none !important;
            }

            &--small {
                margin: 0 0 20px;
                font-size: 1.625rem;
                text-align: center;
            }
        }

        &__buttons {
            justify-content: center;
        }

        .home__navigation {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 20px;

            .swiper-button {
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                margin: 0 15px;
            }
        }


        // Slideshow
        .home-slideshow {
            > .container::before {
                display: none;
            }

            &__image-title {
                span {
                    text-align: center;
                }
    
                &--main {
                    font-size: 3.125rem;
                }
                &--secondary {
                    font-size: 1.5rem;
                }
            }

            .slideshow {
                &__content {
                padding: 30px;

                    &__svg {
                        top: -22px;
                        height: 39px;
                    }
                }
                &__title {
                    font-size: 1.125rem;
                    text-align: center;
                }
                &__description {
                    font-size: 0.875rem;
                    text-align: center;
                }
            }

            .swiper-container, .video-container {
                height: 370px;
            }

            .swiper-container .container--pagination {
                left: 95px;
                top: 130px;
            }
        }



        // Access 
        .access__list li {
            flex: 1 1 50%;
        }


        // News 
        .news {
            &__link {
                width: 100%;
                height: 244px;
                margin: 0;
            }

            &__content {
                height: 156px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            
            &__infos {
                padding: 20px;

                &__title {
                    margin: 0;
                    font-size: 1.25rem;
                }

                &__svg {
                    height: 22px;
                }
            }
        }


        // Events 
        .events {
            .swiper-slide {
                width: 100%;
            }

            &__link {
                margin: 0;
            }
        }


        // Home footer 
        &__footer {
            &__container > svg {
                display: none;
            }

            &__list {
                flex-wrap: wrap;
                justify-content: center;

                li {
                    width: 87px;
                    margin: 0 7.5px;
    
                    &:first-child {
                        width: 102px;
                        margin-bottom: 15px;
                    }
    
                    &:nth-child(2) {
                        width: 102px;
                        margin-bottom: 15px;
                    }
                }
            }

        }

    }

}
